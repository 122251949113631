export const COMMENTS = [
    {
        id: 0,
        campsiteId: 0,
        rating: 5,
        text: 'This was a really fun game enjoyed it!',
        author: 'Simon Hogan',
        date: '2022-10-25T15:30Z'
    },
    {
        id: 1,
        campsiteId: 1,
        rating: 5,
        text: 'Really Cool!',
        author: 'McKenzie Smith',
        date: '2022-02-18T13:12Z'
    },
    {
        id: 2,
        campsiteId: 2,
        rating: 5,
        text: 'Love that gadget!',
        author: 'Mike the Snowman',
        date: '2022-02-18T22:12Z'
    },
    
    {
        id: 3,
        campsiteId: 3,
        rating: 4,
        text: 'Love that it\'s on Python',
        author: 'Markell Rich',
        date: '2022-06-17T03:33Z'
    }
    ,
    
    {
        id: 4,
        campsiteId: 4,
        rating: 4,
        text: 'Nice Portfolio Page',
        author: 'Markell Rich',
        date: '2022-06-17T03:37Z'
    }
   
   
];
